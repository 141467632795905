body {
    font:
        400 16px/1.25 Heebo,
        Arial,
        sans-serif;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    color: var(--primaryFontColor1);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

iframe {
    pointer-events: inherit;
}

a,
button {
    cursor: pointer;
    outline: none;
    font-family: Heebo, Arial, sans-serif;
    outline: none;
}

* {
    box-sizing: inherit;
    outline: none;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
}

select::-ms-expand {
    display: none;
}

select:hover,
select:focus {
    outline: none;
    border: none;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.categories-carousel {
    position: relative;
    bottom: 25px;
}

.catalog-layout-paging-dots-container {
    top: -75px !important;
}

.paging-item {
    width: 10px;
}

.paging-dot {
    width: 7px;
    height: 7px;
}

/* .paging-dot > circle {
    r: 3px;
    cx: 10px;
    cy: 10px;
  } */

.catalog-sticky {
    opacity: 0.3;
}

.multiline {
    white-space: pre-wrap;
}

.stick-to-bottom {
    top: initial !important;
    bottom: 0;
}

.hintAngle::before {
    content: '';
    display: block;
    position: absolute;
    transform: rotate(45deg);
    width: 25px;
    height: 25px;
    top: -30px;
    left: 7px;
    background: var(--tertiaryBgColor3);
    border: 1px solid transparent;
    border-color: transparent var(--tertiaryBorderColor2) var(--tertiaryBorderColor2) transparent;
    border-radius: 0 0 5px 0;
}

.buy-now-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--boxShadowColor4);
    z-index: 10000;
}
.buy-now-modal-content {
    height: 90%;
}

.buy-now-modal-content-mobile {
    min-height: 80vh;
}

.pac-container {
    margin-top: 0;
    border-top: 1px solid var(--secondaryBorderColor9);
    background: var(--secondaryBgColor1);
    padding: 15px 0;
    width: 100%;
    border: none;
    box-shadow: none;
}

.pac-item-query,
.pac-item {
    background: none;
    border: none;
    padding: 5px 0;
    font-size: 16px;
    color: var(--secondaryFontColor2);
    cursor: pointer;
}

.pac-item span:not(.pac-matched) {
    margin: 0 3px;
}

.pac-item-query {
    color: var(--primaryFontColor1);
    font-weight: 600;
    margin-right: 5px;
}

.pac-item:hover {
    background: var(--secondaryBgColor16);
}

.pac-icon {
    display: none;
}

.pac-logo:after {
    display: none !important;
}
.styles_rsbcSliderText__3h08j {
    color: var(--primaryFontColor1) !important;
}

.styles_rsbcText__16wA0 {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: var(--secondaryFontColor26) !important;
    letter-spacing: 1px !important;
    font-family: Heebo, Arial, sans-serif !important;
}

.styles_rsbcSliderArrow__2D9q4 {
    height: 12px !important;
    width: 12px !important;
}

.swipe-button {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: var(--secondaryFontColor26) !important;
    letter-spacing: 1px !important;
    font-family: Heebo, Arial, sans-serif !important;
    border: none;
    background: var(--tertiaryBgColor3);
    border-radius: 50px;
    height: 50px;
    width: 100%;
}

.slide-but .swipe-button-overlay {
    background: var(--buttonBlackColor);
    border-radius: 50px;
    height: 50px;
    width: 100%;
    min-width: 50px !important;
    .slide-caret-wrapper {
        box-sizing: border-box;
        background: var(--buttonBlackColor);
        min-width: 50px;
    }
}

.swipe-button-caret {
    float: left;
    position: absolute;
    transform: rotate(45deg);
    border: 2px solid #fff;
    height: 8px;
    width: 8px;
    top: 50%;
    right: 22px;
    margin-top: -6px;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform-origin: center;
    z-index: 10;
}

/* React Modal styling */

.ReactModal__Overlay {
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(100px);
}

/* Carousel-like transition */

.carousel-swap-leave {
    transition: transform 0.4s ease-in-out;
    transform: translate(0, 0);
}
.carousel-swap-leave-active {
    transform: translate(-100%, 0);
}

.carousel-swap-enter {
    transition: transform 0.4s ease-in-out;
    transform: translate(100%, 0);
}
.carousel-swap-enter-active {
    transform: translate(0, 0);
}

.carousel-swap-height {
    transition: height 0.25s ease-in-out 0.2s;
}

.carousel-swap-back-leave {
    transition: transform 0.25s ease-in-out 0.1s;
    transform: translate(0, 0);
}
.carousel-swap-back-leave-active {
    transform: translate(100%, 0);
}

.carousel-swap-back-enter {
    /* transition: transform 0.5s ease-in-out; */
    transform: translate(-100%, 0);
}
.carousel-swap-back-enter-active {
    transform: translate(0, 0);
    transition: transform 0.5s ease-in-out;
}

.carousel-swap-back-height {
    transition: height 0.2s ease-in-out;
}

.cover-images-slider-dots {
    position: absolute;
    width: 100%;
    display: flex !important;
    bottom: 25px;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 480px) {
    .cover-images-slider-dots {
        bottom: 10px;
    }
}

.cover-images-slider-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--primaryBgColor1);
    cursor: pointer;
    color: transparent;
    outline: none;
    border: none;
    padding: 0;
    margin: 0 2px;
    opacity: 0.7;
}

.product-images-paging-dots-container li button {
    background-color: var(--primaryBgColor2);
    opacity: 0.3;
    margin: 0 3px;
}

.product-images-paging-dots-container {
    bottom: -30px;
}

.cover-images-slider-dots li.slick-active button {
    opacity: 1;
}

.slick-next {
    right: 20px !important;
}

.slick-arrow {
    display: none !important;
}

.cover-images-slider-dots li button:before {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    content: '';
    opacity: 0;
}

.delivery-model-toggle .react-toggle-thumb {
    border: 0 !important;
    box-shadow: none !important;
}

.delivery-model-toggle.react-toggle .react-toggle-track:focus,
.delivery-model-toggle.react-toggle .react-toggle-track:hover,
.delivery-model-toggle.react-toggle .react-toggle-track {
    background: var(--scToggleBgColor) !important;
}

.delivery-model-toggle .react-toggle-track {
    width: 45px;
    height: 22px;
}
.delivery-model-toggle.react-toggle--checked .react-toggle-track:focus,
.delivery-model-toggle.react-toggle--checked .react-toggle-track:hover,
.delivery-model-toggle.react-toggle--checked .react-toggle-track {
    background: var(--sfToggleBgColor) !important;
}

.delivery-model-toggle .react-toggle-thumb {
    width: 18px;
    height: 18px;
    top: 2px;
    left: 3px;
}

.delivery-model-toggle.react-toggle--checked .react-toggle-thumb {
    left: 24px;
}

.delivery-model-toggle.react-toggle .react-toggle-track-check {
    margin: 0;
    padding-top: 4px;
}

.delivery-model-toggle.react-toggle .react-toggle-track-x {
    margin: 0;
    right: 7px;
    top: 5px;
}

/* tooltip */
.delivery-model-tooltip-container {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
}

.delivery-model-tooltip-container + .tooltip-container {
    border: 0;
    border-radius: 8px;
    padding: 25px;
    width: 345px;
    color: var(--primaryFontColor2);
    bottom: 25px !important;
}

.delivery-model-tooltip-container.red + .tooltip-container {
    background: var(--quaternaryBgColor);
}

.delivery-model-tooltip-container.grey + .tooltip-container {
    background: var(--scBgColor);
}

.delivery-model-tooltip-container + .tooltip-container .tooltip-arrow {
    display: block;
}
.delivery-model-tooltip-container + .tooltip-container .tooltip-arrow::before {
    border-width: 10px 10px 0 10px !important;
}
.delivery-model-tooltip-container.red + .tooltip-container .tooltip-arrow::after,
.delivery-model-tooltip-container.red + .tooltip-container .tooltip-arrow::before {
    border-color: var(--quaternaryBgColor) transparent transparent transparent;
}

.delivery-model-tooltip-container.grey + .tooltip-container .tooltip-arrow::after,
.delivery-model-tooltip-container.grey + .tooltip-container .tooltip-arrow::before {
    border-color: var(--secondaryBorderColor14) transparent transparent transparent;
}
.delivery-model-tooltip-container + .tooltip-container .tooltip-arrow::after {
    border-width: 10px 10px 0 10px !important;
}

.tooltip-container[data-popper-placement='bottom'] {
    bottom: -5px !important;
}

.delivery-model-tooltip-container
    + .tooltip-container[data-popper-placement='bottom']
    .tooltip-arrow::after {
    display: none !important;
}

.delivery-model-tooltip-container.red
    + .tooltip-container[data-popper-placement='bottom']
    .tooltip-arrow::before {
    top: -12px !important;
    border-width: 10px !important;
    border-color: transparent transparent var(--quaternaryBgColor) transparent !important;
}

.delivery-model-tooltip-container.grey
    + .tooltip-container[data-popper-placement='bottom']
    .tooltip-arrow::before {
    top: -12px !important;
    border-width: 10px !important;
    border-color: transparent transparent var(--secondaryBorderColor14) transparent !important;
}

.sticky-outer-wrapper.active .sticky-options-raw {
    background: url('assets/images/rectangle.svg');
}

.sticky-outer-wrapper.active .sticky-options-raw-dark {
    background: url('assets/images/rectangle-black.svg');
}

.alert-fadeout {
    animation: fadeOut 0.3s linear forwards;
    opacity: 0;
}

@keyframes fadeOut {
    0% {
        opacity: 0.7;
    }
    100% {
        opacity: 0;
    }
}

.horizontal-scrolling {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.horizontal-scrolling::-webkit-scrollbar {
    display: none;
}

.category-carousel .category-carousel-home {
    width: 5%;
}

.category-carousel .category-carousel-nav {
    width: 95%;
}

.slider-wrapper [data-arrow='right'] button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-image: url(./assets/images/right-arrow.svg);
    object-fit: 'contain';
    background-color: 'transparent';
    box-shadow: 'none';
    width: 65px;
    height: 65px;
    position: absolute !important;
    top: 140px;
    right: 0;
    display: none;
}
.category-nav [data-arrow='right'] button {
    top: 70px;
}
.slider-wrapper.slider-wrapper-promotions [data-arrow='right'] button,
.slider-wrapper.slider-wrapper-promotions [data-arrow='left'] button {
    top: 100px;
}

.slider-wrapper [data-arrow='left'] button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-image: url(./assets/images/left-arrow.svg);
    object-fit: 'contain';
    background-color: 'transparent';
    box-shadow: 'none';
    width: 65px;
    height: 65px;
    position: absolute !important;
    top: 140px;
    left: -10px;
    display: none;
}
.category-nav [data-arrow='left'] button {
    top: 70px;
}

.slider-wrapper > div > div {
    align-items: center;
}

@media screen and (max-width: 767px) {
    .slider-wrapper > div > div {
        padding-left: 20px;
    }
}

@media (hover: hover), (-ms-high-contrast: none) {
    .slider-wrapper:hover > div [data-arrow='left'] button {
        display: block;
    }

    .slider-wrapper:hover > div [data-arrow='right'] button {
        display: block;
    }
}

.vertical-scrolling {
    overflow: auto;
    min-height: 350px;
    -ms-overflow-style: -ms-autohiding-scrollbar; /*IE*/
    scrollbar-width: none; /* Firefox */
}

.vertical-scrolling::-webkit-scrollbar {
    display: none;
}

.promoBar-close {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 10px 0;
    margin-left: -2em;
}

.ReactModal__Body--open {
    overflow: visible;
    position: relative;
}

.ReactModal__Body--no_scroll {
    overflow: hidden;
    height: 100vh;
}

.cart-item-qty-select {
    position: relative;
}

.cart-item-selected-value {
    cursor: pointer;
}

.cart-item-select-options {
    position: absolute;
    text-align: right;
    top: 100%;
    right: 0;
    width: 50ox;
    background-color: var(--inputBgColor);
    border: 1px solid var(--secondaryBgColor14);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1;
    max-height: 500px;
    overflow-y: auto;
}

.cart-item-select-option {
    padding: 10px;
    padding-right: 20px;
    cursor: pointer;
}

.cart-item-select-option:hover {
    background-color: #f0f0f0;
}
